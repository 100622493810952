@import "../assets/Styles.scss";

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: $icon-height;
  width: $icon-width;
  .link {
    position: relative;
  }
  .icon-img {
    width: 50px;
    @include stop-image-drag;
    @include pixel-border();
  }
  .shortcut-img {
    width: 14px;
    height: 14px;
    position: absolute;
    bottom: 0;
    left: -5px;
    @include stop-image-drag;
    @include pixel-border(1px);
  }
  .icon-label {
    margin: 3px 0 0 0;
    @include pixeloid-sans(14px, $icon-text-color);
  }
}
