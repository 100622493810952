@import "../assets/Styles.scss";

.background {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 200px;
  @include pixeloid-sans(14px, "grey");
  background-color: #f8f8f8;
}

a {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
  transition: color 0.3s ease;
}

a:hover {
  color: #0056b3;
}
